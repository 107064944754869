/**
 *
 * ResetPassword Page
 *
 *
 */

import React from 'react';
import styled, { ThemeProvider } from 'styled-components';

import SEO from 'components/seo';
import ResetPassword from 'containers/Auth/ResetPassword';
import theme from 'utils/theme';

const ResetPasswordPage = pageProps => (
  <ThemeProvider theme={theme}>
    <Container>
      <SEO title="Forgot Password" />
      <ResetPassword {...pageProps} />
    </Container>
  </ThemeProvider>
);

export default ResetPasswordPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
`;
